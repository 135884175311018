import React from "react";
import "./about.scss";
export const About = () => {
    return (
        <div className="wrapper about-us-container">
            <h1>About Us</h1>
            <p>
                At wstwnd, we are passionate about all things Georgian Bay. From the pristine beaches and rugged
                shoreline to the lush forests and abundant wildlife, Georgian Bay is a truly special place. We believe
                that the natural beauty of this region should be celebrated and shared with the world, which is why
                we've created a line of apparel that embodies the spirit of Georgian Bay. Our clothing is made with
                high-quality materials and features unique designs that reflect the unique character of this area.
                Whether you're a local or just passing through, we hope that our clothing will help you feel connected
                to the natural wonder and rich culture of Georgian Bay.
            </p>
        </div>
    );
};
