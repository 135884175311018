import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { addToBag, removeFromBag, clearBag } from "../../redux/shoppingBagSlice";

import "./shoppingbag.scss";

export const ShoppingBagCounter = () => {
    const { bagItems } = useSelector((state) => state.shoppingBag);
    return bagItems.length;
};

export const ShoppingBag = (props) => {
    const dispatch = useDispatch();
    const { bagItems } = useSelector((state) => state.shoppingBag);

    const totalCost = bagItems
        .reduce((accumulator, item) => {
            return accumulator + parseFloat(item.price);
        }, 0)
        .toFixed(2);

    return (
        <>
            <h3>Your bag</h3>
            <ul>
                {bagItems.length > 0 ? (
                    bagItems.map((item, index) => (
                        <li key={index}>
                            <div className="shopping-bag-item-container">
                                <div className="shopping-bag-item-image">
                                    <img src={item.image}></img>
                                </div>
                                <div className="shopping-bag-item-info">
                                    <h4 className="shopping-bag-item-title">{item.name}</h4>
                                    <p>
                                        {item.colour && item.colour + "/"}
                                        {item.size && item.size}
                                    </p>
                                </div>
                                <div className="shopping-bag-item-price">
                                    <p>${item.price}</p>
                                    <button
                                        className="trash-bin-container"
                                        onClick={() => dispatch(removeFromBag(index))}
                                    >
                                        <DeleteOutlineIcon />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <div className="empty-bag-container">
                        <p>
                            Nothing here yet! Check out our{" "}
                            <span className="link">
                                <Link onClick={() => props.closeMenu("bag")} to="/products">
                                    apparel page
                                </Link>
                            </span>{" "}
                            to add something.
                        </p>
                    </div>
                )}
            </ul>
            {bagItems.length > 0 && (
                <>
                    <div className="subtotal-container">
                        <h4>Subtotal [{bagItems.length} items]:</h4>
                        <h4>${totalCost}</h4>
                    </div>
                    <button className="checkout-btn">Proceed to checkout</button>
                </>
            )}
        </>
    );
};

export const AddToBagButton = (props) => {
    const dispatch = useDispatch();

    const handleAddToBag = (name, price, image, size, colour, quantity) => {
        for (let index = 0; index < quantity; index++) {
            dispatch(
                addToBag({
                    name,
                    price,
                    image,
                    size,
                    colour,
                })
            );
        }
    };

    return (
        <button
            className="add-to-bag-btn"
            onClick={() =>
                handleAddToBag(props.name, props.price, props.image, props.size, props.colour, props.quantity)
            }
        >
            Add to Bag
        </button>
    );
};
