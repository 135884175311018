import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { ProductLoader } from "../../component/loaders/ProductLoader";
import "./products.scss";

const { REACT_APP_API_URL } = process.env;
const PRODUCT_API_URL = REACT_APP_API_URL + "products";

export const Products = () => {
    const [data, setData] = useState(APIproductData);

    // TODO; might remove this entire chunk and create a node script that outputs the info I need into it's own file
    // currently reading everything from an obj that was generatered with the below code. Will need to update when new
    // products get created, but I am not creating them often so storing everything client side is much much much faster.

    // useEffect(() => {
    //     const fetchData = async () => {
    //         let productData = [];
    //         let API_COUNT = 0;
    //         let IND_API_COUNT = 0;

    //         if (localStorage.getItem("product-data")) {
    //             console.log("Has localStage, no API Call");
    //             productData = JSON.parse(localStorage.getItem("product-data"));
    //             setData(productData);
    //         } else {
    //             // const response = await fetch(PRODUCT_API_URL);
    //             // const data = await response.json();
    //             API_COUNT++;
    //             console.log(`All Product Data - ${API_COUNT}`, data);

    //             // only make new calls if length is not the same
    //             // TODO; output new file has the updates
    //             // if (APIproductData.length !== data.data.result.length) {
    //             //     for (const product of data.data.result) {
    //             //         if (productData.indexOf(product.id === -1)) {
    //             //             console.log(PRODUCT_API_URL + "/" + product.id);

    //             //             const productPrice = await fetch(PRODUCT_API_URL + "/" + product.id);
    //             //             const productPriceData = await productPrice.json();
    //             //             IND_API_COUNT++;
    //             //             console.log(`Specific Product Data - ${IND_API_COUNT}`, productPriceData);
    //             //             const productVariants = productPriceData.data.result.sync_variants;

    //             //             const colours = [];
    //             //             const sizes = [];

    //             //             for (const variant of productVariants) {
    //             //                 let variantColour = variant.name.split(" / ")[0].trim().split(" - ")[1];

    //             //                 const variantSize = variant.name.split(" / ")[1];
    //             //                 const variantImage = variant.files[1].preview_url;

    //             //                 if (typeof variantColour === "undefined") variantColour = "white";

    //             //                 const newColour = {
    //             //                     name: variantColour,
    //             //                     image: variantImage,
    //             //                 };

    //             //                 const duplicate = colours.some((colour) => colour.name === newColour.name);
    //             //                 if (!duplicate) colours.push(newColour);

    //             //                 if (sizes.indexOf(variantSize) === -1) sizes.push(variantSize);
    //             //             }

    //             //             // all [0] colours are black; randomize the array index based on the amount of colours
    //             //             const randomIndexForDisplay = +Math.random(colours.length).toFixed(0);

    //             //             productData.push({
    //             //                 id: product.id,
    //             //                 name: productVariants[0].name.split(" - ")[0],
    //             //                 colours: colours,
    //             //                 price: productVariants[0].retail_price,
    //             //                 sizes: sizes,
    //             //                 image: colours[randomIndexForDisplay].image,
    //             //                 initialIndex: randomIndexForDisplay,
    //             //             });
    //             //         }
    //             //     }
    //             //     productData = productData.sort((a, b) => a.price - b.price);
    //             //     localStorage.setItem("product-data", JSON.stringify(productData));
    //             // } else {
    //             productData = APIproductData;
    //             localStorage.setItem("product-data", JSON.stringify(productData));
    //             // }

    //             setData(productData);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const handleSetCurrentBackground = (imageUrl, productId, updatedIndex) => {
        const index = data.findIndex((m) => m.id === productId);
        let updatedProducts = [...data];

        updatedProducts[index].image = imageUrl;
        updatedProducts[index].initialIndex = updatedIndex;
        console.log("updatedProducts", updatedProducts);
        setData(updatedProducts);
    };

    const handleSort = (e) => {
        switch (e.target.value) {
            case "high-low":
                setData(() => [...data.sort((a, b) => b.price - a.price)]);
                break;
            case "low-high":
                setData(() => [...data.sort((a, b) => a.price - b.price)]);
                break;
        }
    };

    const getImageByIdAndColour = (products, id, colour) => {
        const product = products.find((product) => product.id === id);

        if (product) {
            const colorObject = product.colours.find((c) => c.name === colour);
            if (colorObject) {
                return colorObject.image;
            }
        }

        return null; // Return null if the product or color is not found
    };

    return (
        <section className="wrapper">
            <div className="products-container">
                <div className="filters-container">
                    <h2>wstwnd. store products</h2>
                    <div className="sort-by-container">
                        <label htmlFor="sort-by">Sort By</label>
                        <select id="sort-by" className="product-sort-by" onChange={(e) => handleSort(e)}>
                            <option value="low-high">Price: Low to High</option>
                            <option value="high-low">Price: High to Low</option>
                        </select>
                    </div>
                </div>

                <div className="shop-container">
                    {data.length === 0 ? (
                        <ProductLoader />
                    ) : (
                        data?.map((product, i) => (
                            <div className="product-item-container" key={i}>
                                <div className="product-item">
                                    <Link to={"/products/" + product.id} state={{ data: product }}>
                                        <img src={product.image}></img>
                                    </Link>
                                </div>
                                <div className="product-colours-container">
                                    {product.colours.length > 1 &&
                                        product.colours?.map((colour, i) => (
                                            <div
                                                key={i}
                                                className={`product-colour ${colour.name
                                                    .toLowerCase()
                                                    .replace(/ /g, "-")
                                                    .replace(/\//g, "-")}`}
                                                onClick={() => handleSetCurrentBackground(colour.image, product.id, i)}
                                                title={colour.name}
                                            ></div>
                                        ))}
                                </div>
                                <div className="product-info-container">
                                    <h3 className="product-name">{product.name}</h3>
                                    <p className="product-price">${product.price}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </section>
    );
};

// current data / don't need to call APIs if length is equal to initial API response length; I.e. no produts have been updated
export const APIproductData = [
    {
        id: 301371532,
        name: "wstwnd. baseball tee",
        colours: [
            {
                name: "Black/White",
                image: require("../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-black-white-front-6510a649151d5-min.jpg"),
            },
            {
                name: "Heather Denim/Navy",
                image: require("../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-denim-navy-front-6510a649161af-min.jpg"),
            },
            {
                name: "Heather Grey/Heather Charcoal",
                image: require("../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-grey-heather-charcoal-front-6510a649162f9-min.jpg"),
            },
            {
                name: "White/Heather Charcoal",
                image: require("../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-white-heather-charcoal-front-6510a64916451-min.jpg"),
            },
        ],
        price: "27.50",
        sizes: ["XS", "S", "M", "L", "XL"],
        image: require("../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-denim-navy-front-6510a649161af-min.jpg"),
        initialIndex: 1,
    },
    {
        id: 301372774,
        name: "wstwnd. men's sunset classic tee",
        colours: [
            {
                name: "Black",
                image: require("../../assets/apparel/sunset-tee/mens-classic-tee-black-zoomed-in-2-65109f38f269f-min.jpg"),
            },
            {
                name: "Navy",
                image: require("../../assets/apparel/sunset-tee/mens-classic-tee-navy-zoomed-in-3-65109f38f3d8d-min.jpg"),
            },
            {
                name: "Sport Grey",
                image: require("../../assets/apparel/sunset-tee/mens-classic-tee-sport-grey-zoomed-in-2-65109f3905582-min.jpg"),
            },
            {
                name: "White",
                image: require("../../assets/apparel/sunset-tee/mens-classic-tee-white-zoomed-in-65109f3909210-min.jpg"),
            },
        ],
        price: "28.50",
        sizes: ["S", "M", "L", "XL"],
        image: require("../../assets/apparel/sunset-tee/mens-classic-tee-sport-grey-zoomed-in-2-65109f3905582-min.jpg"),
        initialIndex: 2,
    },
    {
        id: 304967991,
        name: "wstwnd. crop",
        colours: [
            {
                name: "Black",
                image: require("../../assets/apparel/crop/womens-embroidered-flowy-crop-tee-black-front-6510a7f714052-min.jpg"),
            },
            {
                name: "Dark Grey Heather",
                image: require("../../assets/apparel/crop/womens-embroidered-flowy-crop-tee-dark-grey-heather-front-6510a7f741f06-min.jpg"),
            },
            {
                name: "Mauve",
                image: require("../../assets/apparel/crop/womens-embroidered-flowy-crop-tee-mauve-front-6510a7f741ffd-min.jpg"),
            },
            {
                name: "Heather Dust",
                image: require("../../assets/apparel/crop/womens-embroidered-flowy-crop-tee-heather-dust-front-6510a7f7420cb-min.jpg"),
            },
        ],
        price: "32.75",
        sizes: ["S", "M", "L", "XL", "2XL"],
        image: require("../../assets/apparel/crop/womens-embroidered-flowy-crop-tee-mauve-front-6510a7f741ffd-min.jpg"),
        initialIndex: 2,
    },
    {
        id: 301371664,
        name: "wstwnd. drawstring bag",
        colours: [
            {
                name: "white",
                image: require("../../assets/apparel/drawstring/all-over-print-drawstring-bag-white-front-6510aa20dd4ef-min.jpg"),
            },
        ],
        price: "33.25",
        sizes: [null],
        image: require("../../assets/apparel/drawstring/all-over-print-drawstring-bag-white-front-6510aa20dd4ef-min.jpg"),
        initialIndex: 0,
    },
    {
        id: 299147171,
        name: "wstwnd. classic tee",
        colours: [
            {
                name: "Black Heather",
                image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-black-heather-front-6510a6ffe063a-min.jpg"),
            },
            {
                name: "Dark Grey Heather",
                image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
            },
            {
                name: "Olive",
                image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-olive-front-6510a7002f9ff-min.jpg"),
            },
            {
                name: "Soft Cream",
                image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-soft-cream-front-6510a70030104-min.jpg"),
            },
            {
                name: "White",
                image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-white-front-6510a70030a51-min.jpg"),
            },
        ],
        price: "34.95",
        sizes: ["XS", "S", "M", "L", "XL"],
        image: require("../../assets/apparel/classic-tee/unisex-staple-t-shirt-olive-front-6510a7002f9ff-min.jpg"),
        initialIndex: 2,
    },
    {
        id: 299147045,
        name: "wstwnd. dad hat",
        colours: [
            {
                name: "Black",
                image: require("../../assets/apparel/hats/classic-dad-hat-black-front-6510a7590e990-min.jpg"),
            },
            {
                name: "White",
                image: require("../../assets/apparel/hats/classic-dad-hat-white-front-6510a759382b2-min.jpg"),
            },
        ],
        price: "38.50",
        sizes: [null],
        image: require("../../assets/apparel/hats/classic-dad-hat-black-front-6510a7590e990-min.jpg"),
        initialIndex: 0,
    },
    {
        id: 301371636,
        name: "wstwnd. unisex hoodie",
        colours: [
            {
                name: "Black",
                image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-black-front-6510a206d7186-min.jpg"),
            },
            {
                name: "Navy Blazer",
                image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-navy-blazer-front-6510a206d73bb-min.jpg"),
            },
            {
                name: "Charcoal Heather",
                image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-charcoal-heather-front-6510a206d8223-min.jpg"),
            },
            {
                name: "Carbon Grey",
                image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-carbon-grey-zoomed-in-6510a206d8f33-min.jpg"),
            },
            {
                name: "White",
                image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-white-zoomed-in-6510a206db7ad-min.jpg"),
            },
        ],
        price: "50.95",
        sizes: ["S", "M", "L", "XL", "2XL", "3XL"],
        image: require("../../assets/apparel/hoodie/unisex-premium-hoodie-black-front-6510a206d7186-min.jpg"),
        initialIndex: 0,
    },
    {
        id: 301371601,
        name: "wstwnd. unisex fleece sweatpants",
        colours: [
            {
                name: "Black",
                image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-black-back-6510a90add24b-min.jpg"),
            },
            {
                name: "Charcoal Heather",
                image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-charcoal-heather-back-6510a90add8f1-min.jpg"),
            },
            {
                name: "Military Green",
                image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-military-green-back-6510a90addadd-min.jpg"),
            },
            {
                name: "Carbon Grey",
                image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-carbon-grey-back-6510a90addd6a-min.jpg"),
            },
            {
                name: "White",
                image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-white-back-6510a90ade086-min.jpg"),
            },
        ],
        price: "51.75",
        sizes: ["XS", "S", "M", "L", "XL"],
        image: require("../../assets/apparel/sweats/unisex-fleece-sweatpants-charcoal-heather-back-6510a90add8f1-min.jpg"),
        initialIndex: 1,
    },
];
