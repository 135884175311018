import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import { useSelector } from "react-redux";
export const LoggedIn = () => {
    const navigate = useNavigate();
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
    const [timeoutTime, setTimeoutTime] = useState(3);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeoutTime((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeoutTime]);

    useEffect(() => {
        if (timeoutTime === 0) {
            navigate("/");
        }
    }, [timeoutTime]);
    return (
        <section className="wrapper">
            <div className="login-container">
                <div className="login-child-container">
                    <div className="successfully-logged-in-container">
                        <div className="home-link">
                            <img className="logo" src={require("../../assets/logos/logo.png")}></img>
                            <h2 className="logo-text">wstwnd.</h2>
                        </div>
                        {user && (
                            <div className="logout-msg">
                                <p>Welcome {user.result.firstName}, hold tight while we redirect you!</p>
                                <p>Redirecting in... {timeoutTime}</p>
                            </div>
                        )}
                        {/* <div>
                            <p>Welcome {user && <span>{user.firstName}</span>}! You have been succesfully logged in.</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};
