import React, { useState, useEffect } from "react";
import "../account.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../redux/authSlice";
import { Input } from "../../../component/input/Input";
export const AccountInfo = () => {
    // First name / last name
    const charOnlyInput = /^[A-Za-z]+$/;
    // Standard email format
    const emailInput =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // a needs a letter, symbol and and be 8 characters minimum. a number
    const passwordInput = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    const [isFormValid, setIsFormValid] = useState(false);

    const dispatch = useDispatch();
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    console.log(user, "USER!");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [hasSuccess, setHasSuccess] = useState(false);
    const [activeIndexes, setActiveIndexes] = useState([]);

    const handleErrorMessage = (errMsg) => {
        console.log("handling error");
        setHasSuccess(false);
        setErrorMessage(errMsg);
        setHasError(true);
        setTimeout(function () {
            setHasError(false);
        }, 5000);
    };

    const handleSuccessMesssage = (sccsMsg) => {
        console.log("handling scuces");
        setHasError(false);
        setSuccessMessage(sccsMsg);
        setHasSuccess(true);
        setTimeout(function () {
            setHasSuccess(false);
        }, 5000);
    };

    const handleChangeInfoClick = (index) => {
        if (activeIndexes.includes(index)) {
            const newActiveIndexes = activeIndexes.filter((activeIndex, i) => activeIndex !== index);
            setActiveIndexes(newActiveIndexes);
        } else {
            setActiveIndexes([...activeIndexes, index]);
        }

        console.log(activeIndexes);
    };

    const navigate = useNavigate();

    const myAccountDetails = {
        firstName: {
            type: "text",
            id: "fist-name",
            label: "First name",
            placeholder: "Jane",
            value: firstName,
            setter: setFirstName,
            ref: null,
            autoComplete: "name",
            validPattern: charOnlyInput,
            errorMessage: "Your first name must contain only letters.",
            formErrSetter: setIsFormValid,
        },
        lastName: {
            type: "text",
            id: "last-name",
            label: "Last name",
            placeholder: "Doe",
            value: lastName,
            setter: setLastName,
            ref: null,
            autoComplete: "family-name",
            validPattern: charOnlyInput,
            errorMessage: "Your last name must contain only letters.",
            formErrSetter: setIsFormValid,
        },
        email: {
            type: "text",
            id: "email",
            label: "Email",
            placeholder: "email@email.com",
            value: email,
            setter: setEmail,
            ref: null,
            autoComplete: "email",
            validPattern: emailInput,
            errorMessage: "Your email seems to be invalid.",
            formErrSetter: setIsFormValid,
        },
        currentPassword: {
            type: "password",
            id: "current-password",
            label: "Current Password",
            placeholder: "Current Password",
            value: currentPassword,
            setter: setCurrentPassword,
            ref: null,
            autoComplete: "current-password",
            validPattern: passwordInput,
            errorMessage: "",
            formErrSetter: setIsFormValid,
        },
        password: {
            type: "password",
            id: "password",
            label: "Password",
            placeholder: "Password",
            value: password,
            setter: setPassword,
            ref: null,
            autoComplete: "current-password",
            validPattern: passwordInput,
            errorMessage: "Your password must contain a letter, symbol and a number and be 8 characters minimum.",
            formErrSetter: setIsFormValid,
        },
        confirmPassword: {
            type: "password",
            id: "confirm-password",
            label: "Confirm password",
            placeholder: "Password",
            value: confirmPassword,
            setter: setConfirmPassword,
            ref: null,
            autoComplete: "new-password",
            validPattern: passwordInput,
            errorMessage: "Your password must contain a letter, symbol and a number and be 8 characters minimum.",
            formErrSetter: setIsFormValid,
        },
    };

    const checkIndexAndState = (stateItem, stateStringName) => {
        if (activeIndexes.includes(stateStringName) && stateItem === "") return true;
    };

    const handleAccountInfoChange = (e) => {
        e.preventDefault();

        if (
            checkIndexAndState(firstName, "firstName") ||
            checkIndexAndState(lastName, "lastName") ||
            checkIndexAndState(email, "email") ||
            checkIndexAndState(password, "currentPassword") ||
            checkIndexAndState(password, "password") ||
            checkIndexAndState(password, "confirmPassword")
        ) {
            handleErrorMessage("Please fill in the fields prior to submitting.");
            return;
        }

        if (!isFormValid) {
            handleErrorMessage("Please deal with errors before proceeding.");
            return;
        }

        // previous user email used to change information
        const oldEmail = user.result.email;
        const userData = {
            oldEmail,
            firstName,
            lastName,
            email,
            currentPassword,
            password,
            confirmPassword,
            activeIndexes,
        };

        dispatch(update(userData));
    };

    useEffect(() => {
        if (isError) {
            handleErrorMessage(message);
        }

        if (isSuccess && activeIndexes.length > 0) {
            const labelArray = activeIndexes.map((key) => {
                return myAccountDetails[key]?.label.toString().toLowerCase() || "";
            });

            let flattenedString = "";
            if (labelArray.length === 1) {
                flattenedString = `${labelArray[0]} has`;
            } else if (labelArray.length === 2) {
                flattenedString = `${labelArray[0]} and ${labelArray[1]} have`;
            } else if (labelArray.length > 2) {
                const lastInput = labelArray.pop();
                flattenedString = `${labelArray.join(", ")}, and ${lastInput}have`;
            }

            handleSuccessMesssage(`Your ${flattenedString} been updated successfully!`);
            (() => setActiveIndexes([]))();
        }
    }, [user, isError, isSuccess, message, navigate, dispatch]);

    useEffect(() => {
        // Update the URL when the component is rendered
        window.history.pushState(null, "", "/account/account-info");
    }, []);

    const myAccInfoInputContainer = (label, userType) => {
        const accountStateName = (label[0].toLowerCase() + label.slice(1)).replace(/ /g, "");
        return (
            <div className="user-info-container">
                <div className="user-info-text-container">
                    <div className="user-info-current-container">
                        <h4>{label}</h4>
                        <p>{label !== "Password" ? userType : "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022"}</p>
                    </div>
                    <div className="user-info-change-btn-container">
                        <button
                            type="button"
                            className="change-info"
                            onClick={() => handleChangeInfoClick(accountStateName)}
                        >
                            Change
                        </button>
                    </div>
                </div>

                {activeIndexes.includes(accountStateName) && label === "Password" && (
                    <Input inputInfo={myAccountDetails.currentPassword} location="account-info" />
                )}

                {activeIndexes.includes(accountStateName) && <Input inputInfo={myAccountDetails[accountStateName]} />}

                {activeIndexes.includes(accountStateName) && label === "Password" && (
                    <Input inputInfo={myAccountDetails.confirmPassword} location="account-info" />
                )}
            </div>
        );
    };

    return (
        <>
            <h3 className="my-account-header">Account Info</h3>
            <form className="login-form" onSubmit={handleAccountInfoChange}>
                {myAccInfoInputContainer("First Name", user.result.firstName)}
                {myAccInfoInputContainer("Last Name", user.result.lastName)}
                {myAccInfoInputContainer("Email", user.result.email)}
                {myAccInfoInputContainer("Password")}

                {hasError ? (
                    <div className="info-message error-message" aria-live="assertive">
                        {errorMessage}
                    </div>
                ) : (
                    <div className="info-message-hidden"></div>
                )}

                {hasSuccess ? (
                    <div className="info-message success-message" aria-live="assertive">
                        {successMessage}
                    </div>
                ) : (
                    <div className="info-message-hidden"></div>
                )}

                {activeIndexes.length > 0 && (
                    <button className="save-info-btn" type="submit">
                        Save Info
                    </button>
                )}
            </form>
        </>
    );
};
