//TODO;
import React, { useState } from "react";
import "./modal.scss";

export const Modal = (props) => {
    const { mainProductImage, setIsModalOpen } = props;

    const handleCloseModal = (e) => {
        if (e.target.localName.toLowerCase() === "img") return;
        setIsModalOpen(false);
        document.body.style.overflow = "";
    };
    return (
        <div className="modal-container" onClick={(e) => handleCloseModal(e)}>
            <div className="img-modal-container">
                <button className="close-btn" onClick={(e) => handleCloseModal(e)}>
                    X
                </button>
                <img src={mainProductImage}></img>
            </div>
        </div>
    );
};
