import React from "react";
import Slider from "react-slick";
import "./slick.scss";
import "./slick-theme.scss";
import "./carousel.scss";

export const Carousel = ({ images, currentIndexToShow }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: !currentIndexToShow ? 0 : currentIndexToShow,
    };

    const renderImages = () =>
        images.map((image, i) => (
            <div key={i}>
                <img src={image.image} alt={`image ${i}`} className="carousel-image" />
            </div>
        ));

    return <Slider {...settings}>{renderImages()}</Slider>;
};
