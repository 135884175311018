import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import shoppingBagReducer from "./shoppingBagSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        shoppingBag: shoppingBagReducer,
    },
});
