//TODO;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "../../component/carousel/Carousel";
import "./home.scss";
export const Home = () => {
    const images = [
        { image: require("../../assets/banners/banner8-min.jpg") },
        { image: require("../../assets/banners/banner5-min.jpg") },
        { image: require("../../assets/banners/banner4-min.jpg") },
        { image: require("../../assets/banners/banner3-min.jpg") },
    ];

    return (
        <section className="wrapper landing-page">
            <div className="hero-image-container">
                <Carousel images={images} />

                <div className="bottom-hero-image-text-container">
                    <div className="hero-image-entrance-text">
                        <h1>Introducing wstwnd.</h1>
                        <p>
                            As the fall season descends upon Georgian Bay, signaling the approaching transition to
                            winter, wstwnd stands prepared to provide you with cozy and fashionable attire for the
                            chilly months ahead.
                        </p>
                        <div className="link-to-products" title="View products">
                            <Link to="/products">Shop our apparel now!</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
