import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
export const Footer = () => {
    return (
        <footer>
            <p className="footer-body-text">
                © 2023. All Rights Reserved.{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </span>
                .{" "}
                <span className="private-terms-span-hover">
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </span>
                .
            </p>
        </footer>
    );
};
