import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import { Input } from "../../component/input/Input";

import { useDispatch, useSelector } from "react-redux";

import { login, logout, reset, register } from "../../redux/authSlice";
// import { useLoginMutation } from "../../redux/authApiSlice";

const _defs = {
    _fn: "",
    _ln: "",
    _em: "",
    _ps: "",
    _cps: "",
};

export const Login = ({ hasAccount }) => {
    // First name / last name
    const charOnlyInput = /^[A-Za-z]+$/;
    // Standard email format
    const emailInput =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // a needs a letter, symbol and and be 8 characters minimum. a number
    // const passwordInput = /\D/;
    const passwordInput = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    //TODO; FRONTEND VALID, SECURE
    const [firstName, setFirstName] = useState(_defs._fn);
    const [lastName, setLastName] = useState(_defs._ln);
    const [email, setEmail] = useState(_defs._em);
    const [password, setPassword] = useState(_defs._ps);
    const [confirmPassword, setConfirmPassword] = useState(_defs._cps);

    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");
    const [hasSuccess, setHasSuccess] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);

    const emailRef = useRef();
    const firstNameRef = useRef();

    const loginInputs = {
        firstName: {
            type: "text",
            id: "fist-name",
            label: "First name",
            placeholder: "Jane",
            value: firstName,
            setter: setFirstName,
            ref: firstNameRef,
            autoComplete: "name",
            validPattern: charOnlyInput,
            errorMessage: "Your first name must contain only letters.",
            formErrSetter: setIsFormValid,
        },
        lastName: {
            type: "text",
            id: "last-name",
            label: "Last name",
            placeholder: "Doe",
            value: lastName,
            setter: setLastName,
            ref: null,
            autoComplete: "family-name",
            validPattern: charOnlyInput,
            errorMessage: "Your last name must contain only letters.",
            formErrSetter: setIsFormValid,
        },
        email: {
            type: "text",
            id: "email",
            label: "Email",
            placeholder: "email@email.com",
            value: email,
            setter: setEmail,
            ref: emailRef,
            autoComplete: "email",
            validPattern: emailInput,
            errorMessage: "Your email seems to be invalid.",
            formErrSetter: setIsFormValid,
        },
        password: {
            type: "password",
            id: "password",
            label: "Password",
            placeholder: "Password",
            value: password,
            setter: setPassword,
            ref: null,
            autoComplete: "current-password",
            validPattern: passwordInput,
            errorMessage: "Your password must contain a letter, symbol and a number and be 8 characters minimum.",
            formErrSetter: setIsFormValid,
        },
        confirmPassword: {
            type: "password",
            id: "confirmPassword",
            label: "Confirm password",
            placeholder: "Password",
            value: confirmPassword,
            setter: setConfirmPassword,
            ref: null,
            autoComplete: "new-password",
            validPattern: passwordInput,
            errorMessage: "Your password must contain a letter, symbol and a number and be 8 characters minimum.",
            formErrSetter: setIsFormValid,
        },
    };

    const handleLogin = (e) => {
        e.preventDefault();
        if (!isFormValid) {
            handleErrorMessage("Please deal with errors before proceeding.");
            return;
        }

        if (email === "" || password === "") {
            handleErrorMessage("Please fill out all fields.");
            return;
        }

        const userData = {
            email,
            password,
        };

        dispatch(login(userData));
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!isFormValid) {
            handleErrorMessage("Please deal with errors before proceeding.");
            return;
        }
        if (firstName === "" || lastName === "" || email === "" || password === "" || confirmPassword === "") {
            handleErrorMessage("Please fill out all fields.");
            return;
        }

        const userData = {
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
        };

        dispatch(register(userData));
    };

    const handleErrorMessage = (errMsg) => {
        setHasSuccess(false);
        setErrorMessage(errMsg);
        setHasError(true);
        setTimeout(function () {
            setHasError(false);
        }, 5000);
    };

    const handleSuccessMesssage = (sccsMsg) => {
        setHasError(false);
        setSuccessMessage(sccsMsg);
        setHasSuccess(true);
        setTimeout(function () {
            setHasSuccess(false);
        }, 5000);
    };

    useEffect(() => {
        if (isError) {
            handleErrorMessage(message);
        }

        if (isSuccess || user) {
            handleSuccessMesssage(hasAccount ? "Successfully logged in!" : "Account created successfully!");
            setTimeout(() => {
                navigate("/loggedin");
            }, 1000);
        }

        dispatch(reset());
    }, [user, isError, isSuccess, message, navigate, dispatch]);

    useEffect(() => {
        if (hasAccount) emailRef.current.focus();
        if (!hasAccount) firstNameRef.current.focus();
    }, [hasAccount]);

    // when navigating reset all defaults
    useEffect(() => {
        setFirstName(_defs._fn);
        setLastName(_defs._ln);
        setEmail(_defs._em);
        setPassword(_defs._ps);
        setConfirmPassword(_defs._cps);
    }, [navigate]);

    return (
        <section className="wrapper">
            <div className="login-container">
                <div className="login-child-container">
                    <div className="logo"></div>

                    <form className="login-form" onSubmit={hasAccount ? handleLogin : handleRegister}>
                        <div className="home-link">
                            <img className="logo" src={require("../../assets/logos/logo.png")}></img>
                            <h2 className="logo-text">wstwnd.</h2>
                        </div>
                        <div className="input-container">
                            {!hasAccount && (
                                <>
                                    <Input inputInfo={loginInputs.firstName} hasAccount={hasAccount} />
                                    <Input inputInfo={loginInputs.lastName} hasAccount={hasAccount} />
                                </>
                            )}
                            <Input inputInfo={loginInputs.email} hasAccount={hasAccount} />
                            <Input inputInfo={loginInputs.password} hasAccount={hasAccount} />

                            {!hasAccount && <Input inputInfo={loginInputs.confirmPassword} hasAccount={hasAccount} />}
                        </div>

                        {hasError ? (
                            <div className="info-message error-message" aria-live="assertive">
                                {errorMessage}
                            </div>
                        ) : (
                            <div className="info-message-hidden"></div>
                        )}

                        {hasSuccess ? (
                            <div className="info-message success-message" aria-live="assertive">
                                {successMessage}
                            </div>
                        ) : (
                            <div className="info-message-hidden"></div>
                        )}

                        <button type="submit" className="login-button">
                            {hasAccount ? "Log in" : "Create account"}
                        </button>
                    </form>
                    <div className="sign-up-container">
                        {hasAccount ? (
                            <p className="sign-up-text-container">
                                <span>No account? </span>
                                <Link to="/signup">
                                    <button className="sign-up-btn">Create one</button>
                                </Link>
                            </p>
                        ) : (
                            <p className="sign-up-text-container">
                                <span>Already have one? </span>
                                <Link to="/login">
                                    <button className="login-in-btn">Login</button>
                                </Link>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
