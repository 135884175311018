import React from "react";
import { Link } from "react-router-dom";
import "../documents.scss";

export const Terms = () => {
    return (
        <div className="wrapper document-container">
            <h1>Terms and Conditions</h1>

            <h2>Terms & Conditions</h2>

            <p>
                wstwnd., (“wstwnd”, “we”, “us”) created these Terms and Conditions which apply to your use of
                www.wstwndapparel.com and any related domains and their subdomains (the “wstwnd Sites”) and to sales
                transactions made through the wstwnd Sites. It also applies to your access of text, images, graphics,
                software, source code, applications, specifications, audio files, videos, articles, trademarks, logos
                and other information or content available through the wstwnd Sites, including the “look and feel” and
                arrangement of such content (the “Content”).
            </p>
            <p>
                Your use of the wstwnd Sites and the Content, and your purchase of any products offered on the wstwnd
                Sites, is conditioned on your acceptance of these Terms and Conditions and the{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </span>
                , which is incorporated into these Terms and Conditions by reference. Please read these Terms and
                Conditions and wstwnd’s{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </span>{" "}
                carefully. BY USING THE wstwnd SITES, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND BY OUR{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                </span>
                . If you do not accept these Terms of Use, you should immediately exit the wstwnd Sites and discontinue
                your use of the Content.
            </p>

            <p>
                These Terms and Conditions and the{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </span>{" "}
                were last updated on August 29, 2022. We reserve the right to revise these terms from time to time by
                posting an updated version on the wstwnd Sites, without further notice to you.
            </p>

            <h2>Third Party Sites</h2>

            <p>
                The wstwnd Sites may contain links to websites and other resources operated by third parties other than
                wstwnd, including social media websites. We do not control these websites, and are not responsible for
                the content, products, services or information they provide. Your use of third party websites is
                governed by the terms and conditions and privacy policies of the third party.
            </p>

            <h2>Terms of Sale</h2>

            <p>
                By placing an order on the wstwnd Sites, you offer to purchase the products listed in your order. You
                may check and correct any input errors in your order up until the point at which you submit your order
                to us on the checkout page. By submitting your order, you agree to pay for the Products you have
                ordered.
            </p>

            <p>
                Online payment transactions are also subject to validation checks by your card issuer to verify your
                identity and ensure the security of the transaction. We do not control these checks and are not
                responsible if your card issuer declines to authorize payment for your order.
            </p>

            <h2>Order Confirmation</h2>

            <p>
                Once you have placed your order, we will send you an order confirmation to the email address you
                provide, confirming that your order has been received. All orders are subject to our acceptance. We
                retain the right to reject all orders at our sole discretion, including if: (a) products become
                unavailable, or (b) an order appears abnormal or suspicious. If your order is rejected, we will provide
                you with written notice and a refund or credit.
            </p>

            <h2>Product Availability and Price</h2>

            <p>
                We cannot guarantee the availability of products listed on the wstwnd Sites and we reserve the right to
                discontinue, replace, restock, add or change any product at any time. The product you receive may vary
                slightly from the photographs on the wstwnd Sites for technical reasons, including your screen’s display
                capabilities, or due to minor modifications to the products. You will not be entitled to a return based
                solely upon immaterial variations from the photographs on the wstwnd Sites.
            </p>

            <p>
                The price charged for a product is the price quoted on the wstwnd Sites when you place your order. We
                may change prices at any time without notice to you, and you will not be entitled to a partial or
                complete refund by virtue of any change in price that occurs after you have placed your order.
            </p>

            <p>
                Sales tax will be added to your order where required by law. Shipping charges may also apply in certain
                circumstances, such as if your order is below the threshold for free shipping, if you are shipping
                outside of Canada and the contiguous US States or if you request express shipping. You will have an
                opportunity to review these charges before placing your order. Your bank or credit card company may also
                charge you additional fees. If you ship a product internationally, your order may be opened and
                inspected by customs authorities and may be subject to import duties and taxes when your delivery
                reaches its final destination. By placing your order, you accept and agree to pay all taxes, transaction
                fees and shipping charges associated with your order.
            </p>

            <h2>Delivery</h2>

            <p>
                Your order will be delivered to the shipping address indicated when the order was placed. We will make
                reasonable efforts to fulfill your order by the estimated delivery date stated in our shipping policies
                within our Help Center. Delivery within these timeframes is not guaranteed, and we are not responsible
                for any delays caused by any circumstances outside our reasonable control, including weather, strikes,
                acts or war or terror, errors or omissions by third parties, errors or omissions by you, or the
                decreased availability of transportation services.
            </p>

            <p>
                If you would like to return or exchange a product, you will be subject to our return policy. More
                information about our return policy and shipping practices can be found on our Help Center page on the
                wstwnd Sites. These policies and practices are expressly incorporated into these Terms and Conditions by
                reference. Nothing in these Terms and Conditions affects your legal rights under applicable consumer
                protection laws.
            </p>

            <h2>Discount Codes</h2>

            <p>
                Discount Codes are not redeemable for cash, are non-transferrable, and are only redeemable to purchase
                wstwnd merchandise from our online store. Only valid discount codes provided or promoted directly by
                wstwnd will be honored at checkout. Discount codes supplied or promoted by third parties unauthorized by
                wstwnd (including any unauthorized third party websites) will not be considered valid. Each discount
                code issued is only valid for one use per account holder and is subject to any additional terms and
                conditions as stated in the original offer. Certain merchandise may be excluded from the use of discount
                codes upon checkout. Discount codes must be applied at the time of checkout. Should you need to reach
                out to have a discount code retroactively applied due to a checkout error, make your request in writing
                to wstwnd@gmail.com and the request may be approved or denied at the sole discretion of wstwnd. wstwnd
                reserves the right to disable any and all discount codes during sales and site-wide promotions.
            </p>

            <h2>Data Privacy</h2>
            <p>
                By using our website or sharing your personal information with us, you are accepting and consenting to
                the practices described in this{" "}
                <span className="private-terms-span-hover">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </span>
                . If you wish to have your data erased via the official data erasure process please reach out to
                wstwnd@gmail.com to have a member of our team perform this action.
            </p>

            <h2>Marketing Communications</h2>

            <p>
                Upon visiting the wstwnd Sites and providing your email via the membership portal, a sales associate or
                our support team, you are communicating with us electronically. In doing so, you consent to receive
                communications from us electronically. All of our online marketing communications contain an unsubscribe
                button (reply STOP via SMS or click unsubscribe at the bottom of an email) if you wish to stop receiving
                notifications via that channel of communication. If you wish to fully unsubscribe from all
                communications with wstwnd please reach out to wstwnd@gmail.com to have a member of our team thoroughly
                remove you from all mailing lists and newsletters.
            </p>
        </div>
    );
};
