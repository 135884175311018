import React, { useState, useEffect } from "react";
import "../account.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Orders = () => {
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const [ordersToDate, setOrdersToDate] = useState([
        {
            orderId: "#B8KD8JL",
            orderDate: "2023-06-19",
            orderStatus: "Delivered",
            orderDelieveryDate: "2023-11-04",
            orderTotal: "76.54",
            orderContents: [
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-black-white-front-6510a649151d5-min.jpg"),
                    size: "S",
                    colour: "Black/White",
                },
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-grey-heather-charcoal-front-6510a649162f9-min.jpg"),
                    size: "S",
                    colour: "White/Heather Charcoal",
                },
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
            ],
            shippingAddress: {
                name: "Ben Sarjeant",
                address1: "58 Orchard View Blvd",
                address2: "Unit 1701",
                city: "Toronto, Ontario",
                code: "M4R 0A2",
            },
        },
        {
            orderId: "#A9DG6JL",
            orderDate: "2023-06-19",
            orderStatus: "In transit",
            orderDelieveryDate: "2023-11-04",
            orderTotal: "105.64",
            orderContents: [
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-grey-heather-charcoal-front-6510a649162f9-min.jpg"),
                    size: "S",
                    colour: "White/Heather Charcoal",
                },
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-black-white-front-6510a649151d5.jpg"),
                    size: "S",
                    colour: "Black/White",
                },

                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
            ],
            shippingAddress: {
                name: "Ben Sarjeant",
                address1: "58 Orchard View Blvd",
                address2: "Unit 1701",
                city: "Toronto, Ontario",
                code: "M4R 0A2",
            },
        },
        {
            orderId: "#J83MLKD",
            orderDate: "2023-06-19",
            orderStatus: "Shipped",
            orderDelieveryDate: "2023-11-04",
            orderTotal: "175.64",
            orderContents: [
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                    orderTotal: "36.12",
                },
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-black-white-front-6510a649151d5.jpg"),
                    size: "S",
                    colour: "Black/White",
                },

                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-grey-heather-charcoal-front-6510a649162f9-min.jpg"),
                    size: "S",
                    colour: "White/Heather Charcoal",
                },
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
            ],
            shippingAddress: {
                name: "Ben Sarjeant",
                address1: "58 Orchard View Blvd",
                address2: "Unit 1701",
                city: "Toronto, Ontario",
                code: "M4R 0A2",
            },
        },
        {
            orderId: "#3KF8SL",
            orderDate: "2023-06-19",
            orderStatus: "Shipped",
            orderDelieveryDate: "2023-11-04",
            orderTotal: "93.23",
            orderContents: [
                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-black-white-front-6510a649151d5.jpg"),
                    size: "S",
                    colour: "Black/White",
                },

                {
                    id: 301371532,
                    name: "wstwnd. baseball tee",
                    price: "27.50",
                    image: require("../../../assets/apparel/baseball/unisex-34-sleeve-raglan-shirt-heather-grey-heather-charcoal-front-6510a649162f9-min.jpg"),
                    size: "S",
                    colour: "White/Heather Charcoal",
                },
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
                {
                    id: 299147171,
                    name: "wstwnd. classic tee",
                    price: "34.95",
                    image: require("../../../assets/apparel/classic-tee/unisex-staple-t-shirt-dark-grey-heather-front-6510a7002f419-min.jpg"),
                    size: "S",
                    colour: "Dark Grey Heather",
                },
            ],
            shippingAddress: {
                name: "Ben Sarjeant",
                address1: "58 Orchard View Blvd",
                address2: "Unit 1701",
                city: "Toronto, Ontario",
                code: "M4R 0A2",
            },
        },
    ]);

    const [activeIndexes, setActiveIndexes] = useState([]);
    const [chevronStates, setChevronStates] = useState(Array(ordersToDate.length).fill(false));

    const handleQuestionClick = (index) => {
        setActiveIndexes((prevActiveIndexes) => {
            if (prevActiveIndexes.includes(index)) {
                return prevActiveIndexes.filter((activeIndex) => activeIndex !== index);
            } else {
                return [...prevActiveIndexes, index];
            }
        });

        setChevronStates((prevChevronStates) => {
            const newChevronStates = [...prevChevronStates];
            newChevronStates[index] = !prevChevronStates[index];
            return newChevronStates;
        });
    };

    const getFullSizeName = (sizeShort) => {
        if (sizeShort === "XS") return "Extra Small";
        if (sizeShort === "S") return "Small";
        if (sizeShort === "M") return "Medium";
        if (sizeShort === "L") return "Large";
        if (sizeShort === "XL") return "Extra Large";
    };

    useEffect(() => {
        // Update the URL when the component is rendered
        window.history.pushState(null, "", "/account/orders");
    }, []);

    function specificDetails(orderInfo) {
        return orderInfo.orderContents.map((item, i) => {
            return (
                <div key={i} className="order-specific-container">
                    <div className="order-item-image">
                        <img src={item.image} alt={item.name + "image"} />
                    </div>
                    <div className="order-item-information-container">
                        {/* <Link to={"/products/" + item.id}>
                        </Link> */}

                        <div className="order-item-name">{item.name}</div>
                        <div className="order-item-information">
                            <p>${item.price}</p>
                            <p>{getFullSizeName(item.size)}</p>
                            <p>{item.colour}</p>
                        </div>
                    </div>
                </div>
            );
        });
    }

    const orderDetails = ordersToDate.map((order, index) => {
        const isChevronClicked = chevronStates[index];
        const chevronStyles = {
            transition: "transform 0.3s",
            transform: `rotate(${isChevronClicked ? 90 : 0}deg)`,
        };

        return (
            <div
                key={index}
                className={`order-item-container ${activeIndexes.includes(index) && "active"} `}
                onClick={() => handleQuestionClick(index)}
            >
                <div className="fl-row" title="View details">
                    <div className="order-image-container">
                        <img src={order.orderContents[0].image} alt="product" />
                    </div>{" "}
                    <div className="order-details-parent-container">
                        <div className="order-details-container">
                            <div className="order-container-top">
                                <h4 className="order-name">
                                    {order.orderContents[0].name} and {order.orderContents.length - 1} others...{" "}
                                </h4>
                                <p className="order-id">{order.orderId}</p>
                            </div>
                            <div className="main-details-container-left">
                                <div className="order-container-bottom">
                                    <p className="order-info">Status: {order.orderStatus} </p>
                                    <p className="order-info">Total: ${order.orderTotal} </p>
                                    <p className="order-info">Date: {order.orderDate} </p>
                                    <p className="order-info">
                                        {order.orderStatus !== "Delivered" && "Estimated"} Delivery: {order.orderDate}{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chevron-container">
                        <ExpandMoreIcon style={chevronStyles} />
                    </div>
                </div>
                {activeIndexes.includes(index) && (
                    <>
                        <div className="shipping-information">
                            <h5 className="shipping-title">Your order was shipped to: </h5>
                            <div className="shipping-information-specifics">
                                <div>
                                    <p>{order.shippingAddress.name}</p>
                                    <p>{order.shippingAddress.address1}</p>
                                    <p>{order.shippingAddress.address2}</p>
                                </div>
                                <div>
                                    <p>{order.shippingAddress.city}</p>
                                    <p>{order.shippingAddress.code}</p>
                                </div>
                            </div>
                        </div>
                        <div className="order-specific-parent-container">{specificDetails(order)}</div>
                    </>
                )}
            </div>
        );
    });

    return (
        <div className="orders-container">
            <h3 className="my-account-header">
                Orders - <i>Fake order data until development is complete - design in progress.</i>
            </h3>
            {orderDetails}
        </div>
    );
};
