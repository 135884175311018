import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./header.scss";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { ShoppingBag, ShoppingBagCounter } from "../bag/ShoppingBag";
import { login, logout, reset } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";

export const Header = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
    const dispatch = useDispatch();
    const [isBagMenuShown, setBagMenuShown] = useState("hidden");
    const [isBagMenuClicked, setIsBagMenuClicked] = useState(false);

    const [isHamburgerMenuShown, setHamburgerMenuShown] = useState("hidden");
    const [isHamburgerMenuClicked, setIsHamburgerMenuClicked] = useState(false);
    const [isHamburgerIconClicked, setIsHamburgerIconClicked] = useState("unclicked");

    const { user } = useSelector((state) => state.auth);

    const updateMenu = (menuName) => {
        let setShownState;
        let clickedState;
        let setClickedState;
        let setIconClickedState;

        // close bag menu
        if (menuName !== "bag") setBagMenuShown("hidden");
        if (menuName !== "bag") setIsBagMenuClicked(false);
        if (menuName !== "hamburger") setHamburgerMenuShown("hidden");
        if (menuName !== "hamburger") setIsHamburgerMenuClicked(false);
        if (menuName !== "hamburger") setIsHamburgerIconClicked("unclicked");

        if (menuName === "bag") {
            setShownState = setBagMenuShown;
            clickedState = isBagMenuClicked;
            setClickedState = setIsBagMenuClicked;
        }
        if (menuName === "hamburger") {
            setShownState = setHamburgerMenuShown;
            clickedState = isHamburgerMenuClicked;
            setClickedState = setIsHamburgerMenuClicked;
            setIconClickedState = setIsHamburgerIconClicked;
        }

        if (!clickedState) {
            setShownState("visible");
            if (menuName === "hamburger") setIconClickedState("clicked");
        } else {
            setShownState("hidden");
            if (menuName === "hamburger") setIconClickedState("unclicked");
        }
        setClickedState(!clickedState);
    };

    const resetAllMenuStates = () => {
        setBagMenuShown("hidden");
        setIsBagMenuClicked(false);
        setHamburgerMenuShown("hidden");
        setIsHamburgerMenuClicked(false);
        setIsHamburgerIconClicked("unclicked");
    };

    const handleLogout = () => {
        dispatch(logout());

        updateMenu("hamburger");
    };

    useEffect(() => {
        const handleWindowResize = () => {
            if (isMobile) resetAllMenuStates();
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    return (
        <>
            <header>
                <div className="site-in-progress-banner">
                    <span> Site is currently under development.</span>
                </div>
                <nav>
                    <Link to="/" onClick={() => resetAllMenuStates()}>
                        <div className="home-link" title="Go back home">
                            <img className="logo" src={require("../../assets/logos/logo-header.png")}></img>
                            <h2 className="logo-text">wstwnd.</h2>
                        </div>
                    </Link>

                    {!isMobile ? (
                        <ul className="main-nav">
                            <li className="link-li" title="View products">
                                <Link to="/products">Shop our apparel now!</Link>
                            </li>
                        </ul>
                    ) : null}
                    <div className="nav-menu-container">
                        <div className="relative" title="View your shopping bag" onClick={() => updateMenu("bag")}>
                            <ShoppingBagOutlinedIcon fontSize="medium" />
                            <div className="shopping-bag-counter">{ShoppingBagCounter()}</div>
                        </div>
                        <div className="burger-container" title="Open navigation">
                            <div className="burger-menu" onClick={() => updateMenu("hamburger")}>
                                <div className={`burger-bar ${isHamburgerIconClicked}`}></div>
                                <div className={`burger-bar ${isHamburgerIconClicked}`}></div>
                                <div className={`burger-bar ${isHamburgerIconClicked}`}></div>
                            </div>
                        </div>

                        {isHamburgerMenuClicked && (
                            <div className={`menu-container ${!isHamburgerMenuClicked && "hidden"}`}>
                                <div className={`menu ${isHamburgerMenuShown}`}>
                                    <ul className="link-items">
                                        <li className="link-li" title="View products">
                                            <Link onClick={() => updateMenu("hamburger")} to="/products">
                                                Shop our apparel now!
                                            </Link>
                                        </li>
                                        <li className="link-li" title="View about us section">
                                            <Link onClick={() => updateMenu("hamburger")} to="/about">
                                                About us
                                            </Link>
                                        </li>
                                        <li className="link-li" title="View FAQ section">
                                            <Link onClick={() => updateMenu("hamburger")} to="/faq">
                                                Frequenty asked questions
                                            </Link>
                                        </li>
                                        {user && (
                                            <li className="link-li" title="View your account">
                                                <Link
                                                    onClick={() => updateMenu("hamburger")}
                                                    to="/account/account-info"
                                                >
                                                    My account
                                                </Link>
                                            </li>
                                        )}
                                        {/* <li className="link-li">
                                            <Link onClick={() => updateMenu("hamburger")} to="/terms-and-conditions">
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                        <li className="link-li">
                                            <Link onClick={() => updateMenu("hamburger")} to="/privacy-policy">
                                                Private Policy
                                            </Link>
                                        </li> */}
                                    </ul>
                                    <div className="login-signup-container">
                                        {!user ? (
                                            <>
                                                <Link onClick={() => updateMenu("hamburger")} to="/login">
                                                    <button className="login-button">Login</button>
                                                </Link>
                                                <Link onClick={() => updateMenu("hamburger")} to="/signup">
                                                    <button className="login-button">Sign up</button>
                                                </Link>
                                            </>
                                        ) : (
                                            <Link onClick={() => handleLogout()} to="/loggedout">
                                                <button className="login-button">Sign out</button>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {isBagMenuClicked && (
                            <div className={`menu-container ${!isBagMenuClicked && "hidden"}`}>
                                <div className={`menu shopping-bag-menu ${isBagMenuShown}`}>
                                    <ShoppingBag closeMenu={updateMenu} />
                                </div>
                            </div>
                        )}
                    </div>
                </nav>
            </header>
            <div
                onClick={isBagMenuClicked ? () => resetAllMenuStates() : null}
                className={`background ${isBagMenuClicked ? "visible" : "hidden"}`}
            ></div>
            <div
                onClick={isHamburgerMenuClicked ? () => resetAllMenuStates() : null}
                className={`background ${isHamburgerMenuClicked ? "visible" : "hidden"}`}
            ></div>
        </>
    );
};
