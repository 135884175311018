import React, { useState, useEffect, useRef } from "react";
import "./loader.scss";

export const ProductLoader = () => {
    const preloaderIterater = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <>
            {preloaderIterater.map((arrItem, i) => (
                <div className="loader-product-item-container" key={i}>
                    <div className="loader-product-item"></div>
                    <div className="loader-product-colours-container">
                        <div className="loader-product-colour"></div>
                        <div className="loader-product-colour"></div>
                        <div className="loader-product-colour"></div>
                        <div className="loader-product-colour"></div>
                        <div className="loader-product-colour"></div>
                    </div>
                    <div className="loader-product-info-container">
                        <p className="loader-product-name"></p>
                        <p className="loader-product-price"></p>
                    </div>
                </div>
            ))}
        </>
    );
};
