import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bagItems: [],
};

const shoppingBagSlice = createSlice({
    name: "shoppingBag",
    initialState,
    reducers: {
        addToBag: (state, action) => {
            const item = action.payload;
            state.bagItems.push(item);
        },
        removeFromBag: (state, action) => {
            const index = action.payload;
            state.bagItems = state.bagItems.filter((item, i) => i !== index);
        },
        clearBag: (state) => {
            state.bagItems = [];
        },
    },
});

export const { addToBag, removeFromBag, clearBag } = shoppingBagSlice.actions;

export default shoppingBagSlice.reducer;
