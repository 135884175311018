import React from "react";
import "./contact.scss";
import { Link } from "react-router-dom";
export const Contact = () => {
    return (
        <section className="wrapper contact-container">
            <div className="contact-text">
                <h1>Contact Us</h1>
                <h2>Let's Chat</h2>
                <p>
                    Want to get in touch? We'd love to hear from you. If you haven't already, check our
                    <Link to="/faq"> FAQ </Link> page as your answer may be there. If not feel free to send us an email
                    any time at the address below.
                </p>
                <a class="email-us" href="mailto:wstwndapparel@gmail.com">
                    wstwndapparel@gmail.com
                </a>
            </div>

            <div className="business-hours-container">
                <div className="business-hours-content">
                    Business hours Monday - Friday 10:00AM to 5:00PM PST Saturday and Sunday - Closed Canadian Holidays
                    - Closed
                </div>
            </div>
        </section>
    );
};
