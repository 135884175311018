import React, { useState, useEffect } from "react";
import "./account.scss";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { AccountInfo } from "./account-information/AccountInfo";
import { Cart } from "./cart/Cart";
import { Orders } from "./orders/Orders";

import { refresh } from "../../redux/authSlice";

export const Account = () => {
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const location = useLocation();
    const dispatch = useDispatch();

    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [showOrders, setShowOrder] = useState(false);

    useEffect(() => {
        setShowAccountInfo(false);
        setShowCart(false);
        setShowOrder(false);

        if (!user) location.pathname = "/login";

        // Refresh isn't working as of yet. Still need to figure out why.
        // dispatch(refresh(user));

        // Check if the URL matches the desired path for the subcomponent
        if (location.pathname === "/account/account-info") setShowAccountInfo(true);
        if (location.pathname === "/account/orders") setShowOrder(true);
        if (location.pathname === "/account/cart") setShowCart(true);
    }, [location.pathname]);

    return (
        <>
            {user && (
                <div className="wrapper my-acount-parent-container">
                    <div className="my-account-left-container">
                        <ul className="my-account-nav-container">
                            <li
                                title="View your account information"
                                className={`my-account-link-item ${showAccountInfo && "selected"}`}
                            >
                                <Link to="/account/account-info">Account Information</Link>
                            </li>
                            <li title="View your orders" className={`my-account-link-item ${showOrders && "selected"}`}>
                                <Link to="/account/orders">View orders</Link>
                            </li>
                            <li
                                title="View your current cart"
                                className={`my-account-link-item ${showCart && "selected"}`}
                            >
                                <Link to="/account/cart">View cart</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="my-account-right-container">
                        {showAccountInfo && <AccountInfo />}
                        {showOrders && <Orders />}
                        {showCart && <Cart />}
                    </div>
                </div>
            )}
        </>
    );
};
