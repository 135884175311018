import React, { useState, useEffect } from "react";
import "../account.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ShoppingBag } from "../../../component/bag/ShoppingBag";
export const Cart = () => {
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    useEffect(() => {
        // Update the URL when the component is rendered
        window.history.pushState(null, "", "/account/cart");
    }, []);

    return (
        <>
            <ShoppingBag />
        </>
    );
};
