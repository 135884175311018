import React, { useEffect, useState } from "react";
import "./input.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

export const Input = (props) => {
    const {
        type,
        id,
        label,
        value,
        setter,
        ref,
        autoComplete,
        validPattern,
        errorMessage,
        formErrSetter,
        placeholder,
    } = props.inputInfo;

    const { hasAccount } = props;
    const { location } = props;
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [updatedErrMsg, setUpdatedErrMsg] = useState(errorMessage);

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const validateInput = (val) => {
        // don't want to validate password for already created accounts.
        if (val !== "" && (validPattern.test(val) || (id === "password" && hasAccount) || id === "current-password")) {
            setIsValid(true);
            formErrSetter(true);
        } else {
            setIsValid(false);
            formErrSetter(false);
            if (val === "") {
                setUpdatedErrMsg("This field cannot be blank.");
            } else {
                setUpdatedErrMsg(errorMessage);
            }
        }
    };

    useEffect(() => {
        setIsValid(true);
    }, [navigate]);

    return (
        <div className="individual-input-container">
            <label htmlFor={id}>{label}</label>
            <input
                ref={ref && ref}
                type={showPassword ? "text" : type}
                id={id}
                value={value}
                placeholder={placeholder}
                onBlur={(e) => validateInput(e.target.value)}
                onFocus={() => setIsValid(true)}
                onChange={(e) => setter(e.target.value)}
                autoComplete={autoComplete}
                className={isValid ? undefined : "err"}
            ></input>
            {!isValid && <span className="input-err-span">{updatedErrMsg}</span>}
            {type === "password" &&
                (showPassword ? (
                    <VisibilityOffIcon onClick={() => handleShowPassword()} tabIndex="0" />
                ) : (
                    <VisibilityIcon onClick={() => handleShowPassword()} tabIndex="0" />
                ))}
        </div>
    );
};
