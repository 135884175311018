import axios from "axios";

const { REACT_APP_API_URL } = process.env;
// Register user
const register = async (userData) => {
    const response = await axios.post(REACT_APP_API_URL + "register", userData);

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
};
// update user
const update = async (userData) => {
    try {
        const response = await axios.put(REACT_APP_API_URL + "register", userData);

        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    } catch (error) {
        console.error("Error with the update:", error);
        throw error;
    }
};

// Login user
const login = async (userData) => {
    const response = await axios.post(REACT_APP_API_URL + "auth/login", userData);

    if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
};

// Logout user
const logout = async () => {
    const response = await axios.post(REACT_APP_API_URL + "auth/logout");
    if (response.status === 204 || response.status === 200) {
        localStorage.removeItem("user");
    }

    return response.data;
};
// Refresh JWT
const refresh = async () => {
    try {
        const response = await axios.get(REACT_APP_API_URL + "auth/refresh", {
            withCredentials: true, // Include this option to send cookies
        });

        return response.data;
    } catch (error) {
        console.error("Error refreshing JWT:", error);
        throw error;
    }
};

const authService = {
    register,
    logout,
    login,
    update,
    refresh,
};

export default authService;
