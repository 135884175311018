import React, { useState } from "react";
import "./faq.scss";
export const Faq = () => {
    const [faqs, setFaqs] = useState([
        {
            question: "What is wstwnd and what do you sell?",
            answer: "Wstwnd is an apparel company that specializes in Georgian Bay-themed clothing. We sell a variety of items such as t-shirts, hoodies, hats, and more.",
        },
        {
            question: "Where is your company based?",
            answer: "Our company is based in Ontario, Canada, but we ship our products worldwide.",
        },
        {
            question: "How long will it take to receive my order?",
            answer: "Shipping times may vary depending on your location and shipping method selected at checkout. Typically, orders are processed and shipped within 1-3 business days, and delivery times can range from 3-10 business days.",
        },
        {
            question: "What is your return policy?",
            answer: "We accept returns within 30 days of purchase as long as the item is unused, unworn, and in its original condition. Customers are responsible for return shipping costs, but we will provide a full refund for the item.",
        },
        {
            question: "Can I exchange an item for a different size or color?",
            answer: "Yes, we offer exchanges for different sizes or colors. Customers are responsible for shipping costs associated with the exchange.",
        },
        {
            question: "How do I know what size to order?",
            answer: "We provide size charts on each product page to help you determine the best size to order. If you have any questions or concerns, please contact our customer support team.",
        },
        {
            question: "What payment methods do you accept?",
            answer: "We accept major credit cards such as Visa, Mastercard, and American Express.",
        },
        {
            question: "Do you offer gift cards?",
            answer: "We currently do not offer gift cards, but we plan to introduce them in the near future.",
        },
        {
            question: "How can I contact customer support?",
            answer: "To contact our customer support team, please email us at wstwndapparel@gmail.com. We aim to respond to all inquiries within 24-48 hours.",
        },
    ]);

    const [activeIndexes, setActiveIndexes] = useState([]);

    const handleQuestionClick = (index) => {
        if (activeIndexes.includes(index)) {
            const newActiveIndexes = activeIndexes.filter((activeIndex, i) => activeIndex !== index);
            setActiveIndexes(newActiveIndexes);
        } else {
            setActiveIndexes([...activeIndexes, index]);
        }
    };

    return (
        <div className="wrapper faq-container">
            <h2>Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <div key={index} className="faq" onClick={() => handleQuestionClick(index)}>
                    <div className={`faq-question ${activeIndexes.includes(index) && "active"}`}>{faq.question}</div>
                    {activeIndexes.includes(index) && <div className="faq-answer">{faq.answer}</div>}
                </div>
            ))}
        </div>
    );
};
