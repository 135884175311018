import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import "./product.scss";
import { Modal } from "../../component/modal/Modal";
import { AddToBagButton } from "../../component/bag/ShoppingBag";
export const Product = () => {
    // TODO;
    const location = useLocation();
    const { data } = location.state;
    console.log("Product Data: ", data);
    const colours = data.colours;
    const [mainProductImage, setMainProductImage] = useState({});
    const [currentlySelectedIndex, setCurrentlySelectedIndex] = useState(0);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [currentSize, setCurrentSize] = useState("S");
    const [currentColour, setCurrentColour] = useState("S");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(data.initialIndex);

    const handlePrevClick = () => {
        setCurrentIndex((currentIndex - 1 + data.colours.length) % data.colours.length);
    };

    const handleNextClick = () => {
        setCurrentIndex((currentIndex + 1) % data.colours.length);
    };

    const handleSetCurrentBackground = (productId) => {
        setCurrentIndex(productId);
    };

    const handleSetCurrentQuantity = (value) => {
        const numbersRegex = /^\d+$/;
        if (numbersRegex.test(value)) setCurrentQuantity(value);
        if (value === "") setCurrentQuantity("");
    };

    const handleSetCurrentQuantityBlur = (value) => {
        if (value === "") setCurrentQuantity(1);
    };

    const increaseQuantity = () => {
        if (currentQuantity >= 9) return;
        setCurrentQuantity((currentQuantity) => parseInt(currentQuantity) + 1);
    };
    const decreaseQuantity = () => {
        if (currentQuantity <= 1) return;
        setCurrentQuantity((currentQuantity) => parseInt(currentQuantity) - 1);
    };

    const makeOptionTemplate = (value, name, index) => {
        return (
            <option key={index} value={`${value}`}>
                {name}
            </option>
        );
    };

    const determineSize = (size, index) => {
        if (size === "XS") return makeOptionTemplate(size, "Extra Small", index);
        if (size === "S") return makeOptionTemplate(size, "Small", index);
        if (size === "M") return makeOptionTemplate(size, "Medium", index);
        if (size === "L") return makeOptionTemplate(size, "Large", index);
        if (size === "XL") return makeOptionTemplate(size, "Extra Large", index);
    };

    const turnColourNameIntoID = (name) => {
        return name.toLowerCase().replace(/ /g, "-").replace(/\//g, "-");
    };

    const determineColour = (colour, index) => {
        return makeOptionTemplate(turnColourNameIntoID(colour), colour, index);
    };

    const handleShowImageModal = (e) => {
        if (e.target.localName.toLowerCase() === "path") return;
        if (e.target.localName.toLowerCase() === "svg") return;
        if (e.target.localName.toLowerCase() === "span") return;
        setIsModalOpen((prevModalOpen) => !prevModalOpen);
        document.body.style.overflow = "hidden";
    };

    return (
        <section className="product-container wrapper">
            <div className="product-image-container">
                <div className="product-main-image" onClick={(e) => handleShowImageModal(e)}>
                    <div className="img-container image-carousel">
                        {data.colours?.map((image, index) => (
                            <img
                                key={index}
                                src={image.image}
                                alt={`Image ${index + 1}`}
                                className={index === currentIndex ? "active" : index < currentIndex ? "left" : "right"}
                            />
                        ))}
                        <div className="img-directions-container">
                            <span onClick={handlePrevClick}>
                                <ChevronLeftIcon
                                    className={`chevron ${currentlySelectedIndex - 1 < 0 && "hidden"}`}
                                    fontSize="large"
                                />
                            </span>
                            <span onClick={handleNextClick}>
                                <ChevronRightIcon
                                    className={`chevron ${currentlySelectedIndex + 1 >= colours.length && "hidden"}`}
                                    fontSize="large"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="product-carousel-container">
                    {data.colours?.map((colour, i) => (
                        <button key={i} className="product-carousel-item" onClick={() => handleSetCurrentBackground(i)}>
                            <img className={`${i === currentIndex ? "active" : "inactive"}`} src={colour.image}></img>
                        </button>
                    ))}
                </div>
            </div>

            <div className="product-info-container">
                <h2 className="product-name">{data.name}</h2>
                <h3 className="product-price">${data.price}</h3>

                {data.sizes.length > 1 && (
                    <div className="product-inputs-container">
                        <div className="select-container">
                            <h4>
                                <label htmlFor="select-size">Choose your size</label>
                            </h4>
                            <select
                                id="select-size"
                                className="standard-select"
                                onChange={(e) => setCurrentSize(e.target.value)}
                            >
                                {data.sizes?.map((size, index) => determineSize(size, index))}
                            </select>
                        </div>
                    </div>
                )}

                {data.colours.length > 1 && (
                    <div className="product-inputs-container">
                        <div className="select-container">
                            <h4>
                                <label htmlFor="select-colour">Choose your colour</label>
                            </h4>

                            <select
                                className="standard-select"
                                id="select-colour"
                                value={
                                    Object.keys(data.colours[currentIndex]).length !== 0 &&
                                    turnColourNameIntoID(data.colours[currentIndex].name)
                                }
                                onChange={(e) => handleSetCurrentBackground(e.target.selectedIndex)}
                            >
                                {data.colours?.map((colour, index) => determineColour(colour.name, index))}
                            </select>
                        </div>

                        <div className="product-colours-container">
                            {data.colours?.map((colour, i) => (
                                <div key={i} className="large-product-colour">
                                    <div
                                        className={`product-colour large-colour ${turnColourNameIntoID(colour.name)}`}
                                        onClick={() => handleSetCurrentBackground(i)}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="product-inputs-container">
                    <div className="product-quantity-container">
                        <h4>
                            <label htmlFor="quantity-input" className="product-quantity">
                                Choose your quantity
                            </label>
                        </h4>
                        <div className="product-quantity-input-container">
                            <button className="plus-btn" onClick={increaseQuantity}>
                                <AddCircleOutlineIcon />
                            </button>

                            <input
                                id="quantity-input"
                                className="quantity-input"
                                type="text"
                                placeholder="1"
                                value={currentQuantity}
                                maxLength="1"
                                onChange={(e) => handleSetCurrentQuantity(e.target.value)}
                                onBlur={(e) => handleSetCurrentQuantityBlur(e.target.value)}
                                pattern="\d"
                            ></input>
                            <button className="neg-btn" onClick={decreaseQuantity}>
                                <RemoveCircleOutlineIcon />
                            </button>
                        </div>
                    </div>
                </div>

                <AddToBagButton
                    name={data.name}
                    price={data.price}
                    image={data.colours[currentIndex].image}
                    size={data.sizes.length > 1 ? currentSize : null}
                    colour={data.colours[currentIndex].name}
                    quantity={currentQuantity}
                />
            </div>
            {isModalOpen && (
                <Modal mainProductImage={data.colours[currentIndex].image} setIsModalOpen={setIsModalOpen} />
            )}
        </section>
    );
};
