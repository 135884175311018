import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Header } from "./component/header/Header";
import { Footer } from "./component/footer/Footer";
import { Home } from "./pages/home/Home";
import { Products } from "./pages/products/Products";
import { Product } from "./pages/product/Product";
import { Contact } from "./pages/contact/Contact";
import { Login } from "./pages/login/Login";
import { LoggedIn } from "./pages/login/LoggedIn";
import { LoggedOut } from "./pages/login/LoggedOut";
import { Faq } from "./pages/faq/Faq";
import { About } from "./pages/about/About";
import { Account } from "./pages/account/Account";
import { AccountInfo } from "./pages/account/account-information/AccountInfo";
import { Cart } from "./pages/account/cart/Cart";
import { Orders } from "./pages/account/orders/Orders";
import { Private } from "./pages/documents/private/Private";
import { Terms } from "./pages/documents/terms/Terms";
// import { GoogleOAuthProvider } from "@react-oauth/google";

export const App = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login hasAccount={true} />} />
                <Route path="/signup" element={<Login hasAccount={false} />} />
                <Route path="/loggedin" element={<LoggedIn />} />
                <Route path="/loggedout" element={<LoggedOut />} />
                <Route path="/products/:id" element={<Product />} />
                <Route path="/privacy-policy" element={<Private />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/about" element={<About />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/account-info" element={<Account />} />
                <Route path="/account/cart" element={<Account />} />
                <Route path="/account/orders" element={<Account />} />
            </Routes>
            <Footer />
        </>
    );
};
